<template>
  <v-card :loading="isLoading">
    <v-card-title class="break-word-normal" primary-title>
      <div>
        <v-icon left>
          {{ $vuetify.icons.values.alert }}
        </v-icon>
        {{ $trans("delete_recurring_booking_title") }}
      </div>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">
        {{ $trans("delete_recurring_booking_question") }}
      </p>
      <p class="text-subtitle-1 accent_light--text">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions v-if="!isBlockingSpot">
      <v-checkbox
        v-model="skipNotifications"
        dense
        :disabled="isLoading"
        hide-details
      >
        <template #label>
          <v-tooltip right max-width="400">
            <template #activator="{ on }">
              <div v-on="on">
                <span>{{
                  $trans("booking_updated_no_notifications_label")
                }}</span>
              </div>
            </template>
            {{ $trans("booking_deleted_no_notifications_label") }}
          </v-tooltip>
        </template>
      </v-checkbox>
    </v-card-actions>

    <v-card-actions>
      <v-radio-group
        v-model="deleteEventType"
        dense
        hide-details
        :disabled="isLoading"
      >
        <v-radio
          :value="1"
          :label="$trans('delete_recurring_booking_option_1')"
        />
        <v-radio
          :value="2"
          :label="$trans('delete_recurring_booking_option_2')"
        />
        <v-radio
          :value="3"
          :label="$trans('delete_recurring_booking_option_3')"
        />
      </v-radio-group>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('cancel', false)">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="deleteEvent"
      >
        {{ $trans("delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "RemoveRecurringEventDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      skipNotifications: false,
      isBlockingSpot: false,
      deleteEventType: 1,
    };
  },
  created() {
    this.skipNotifications = this.getConfirmDialog.data.skipNotifications;
    this.isBlockingSpot = this.getConfirmDialog.data.isBlockingSpot;
  },
  methods: {
    ...mapActions({
      deleteMultipleBooking: "booking/deleteMultiple",
      deleteMultipleUnavailableBookingSpots:
        "booking/deleteMultipleUnavailableBookingSpot",
    }),
    deleteEvent() {
      switch (this.deleteEventType) {
        case 1:
          this.deleteSingle();
          break;
        case 2:
          this.deleteMultiple(true);
          break;
        case 3:
          this.deleteMultiple();
          break;
      }
    },
    deleteSingle() {
      this.isLoading = true;

      if (this.isBlockingSpot) {
        this.deleteMultipleUnavailableBookingSpots({
          ids: [this.getConfirmDialog.data.id],
          skip_notifications: this.skipNotifications,
        })
          .then(() => {
            this.handleSuccess();
          })
          .catch((error) => {
            this.handleFail(error);
          });
      } else {
        this.deleteMultipleBooking({
          booking_ids: [this.getConfirmDialog.data.id],
          skip_notifications: this.skipNotifications,
          reload: false,
        })
          .then(() => {
            this.handleSuccess();
          })
          .catch((error) => {
            this.handleFail(error);
          });
      }
    },
    deleteMultiple(startFromCurrentId) {
      this.isLoading = true;

      const payload = {
        group_id: this.getConfirmDialog.data.groupId,
        skip_notifications: this.skipNotifications,
      };

      if (startFromCurrentId) {
        payload.start_from_id = this.getConfirmDialog.data.id;
      }

      let request = "deleteMultipleBooking";
      if (this.isBlockingSpot) {
        request = "deleteMultipleUnavailableBookingSpots";
      }

      this[request](payload)
        .then(() => {
          this.handleSuccess();
        })
        .catch((error) => {
          this.handleFail(error);
        });
    },
    handleSuccess() {
      this.isLoading = false;

      this.$emit("close", true);
    },
    handleFail(error) {
      errorNotification(null, error, false);
      this.isLoading = false;
    },
  },
};
</script>
